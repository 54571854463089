import { Typography, type TypographyProps } from '@mui/material';

export function TypographyClampLines(props: TypographyClampLinesProps) {
  const { lines = 1, children, sx, ...typographyRest } = props;

  return (
    <Typography
      sx={{
        ...sx,
        // https://css-tricks.com/line-clampin/
        overflow: 'hidden',
        display: '-webkit-box',
        '--clamp-lines': lines,
        WebkitLineClamp: 'var(--clamp-lines, 1)',
        WebkitBoxOrient: 'vertical',
      }}
      {...typographyRest}
    >
      {children}
    </Typography>
  );
}
interface TypographyClampLinesProps extends TypographyProps {
  lines: number;
}
